@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:wght@300;400;500;600;700&display=swap');

@import url('https://api.fontshare.com/v2/css?f[]=sharpie@400&f[]=telma@400,500&f[]=montserrat@400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400..900&display=swap');

